import { Link } from 'react-router-dom';
import icon3 from '../../assets/images/menu-icon-3.svg'
import icon4 from '../../assets/images/menu-icon-4.svg'
import iconSubscription from "../../assets/images/icon-subscription.svg"
import iconDashboard from "../../assets/images/icon-dashboard.svg"
import iconComplienceUser from "../../assets/images/icon-compli-user.svg"
import iconImessage from "../../assets/images/icon-monitoring.svg"
import iconReport from "../../assets/images/reportIcon.svg"
import { useEffect } from 'react';
import Service_Checklist from "../../assets/images/Service Checklist.svg"
import log from "../../assets/images/Activity Log.svg"
import flow from "../../assets/images/Flow.svg"
import sm from "../../assets/images/Service Managment.svg"
import CommandCentre from "../../assets/images/Command Center.svg"
import Sensors from "../../assets/images/sensors.svg"
import { useLocation } from 'react-router-dom';
import WebService from '../../Services/WebService';

const VerticalMenu = () => {
    const location = useLocation();
    const isActive = (path: any) => location.pathname === path;
    let role = localStorage.getItem("role");
    console.log(role);

    useEffect(() => {

    }, [])


    return (
        <>
            <div id="vertical_menu" className="verticle-menu px-2" >
                <div className="menu-list">
                    {/* <Link id="t-1" to={'/dashboard'} className="menu-item"> <img src={iconDashboard} alt="icon" className="menu-icon" /> <span className='nav-text'>Dashboard</span></Link> */}
                    {
                        role === "ADMIN" && <>
                            <Link id="t-1" to={'/manage-clients'} className="menu-item" style={{ backgroundColor: isActive('/manage-clients') ? 'olivedrab' : 'transparent' }}> <img src={icon4} alt="icon" className="menu-icon" /> <span className='nav-text'>Manage Client</span></Link>
                            <Link id="t-1" to={'/stations-add'} className="menu-item" style={{ backgroundColor: isActive('/stations-add') ? 'olivedrab' : 'transparent' }}> <img src={icon3} alt="icon" className="menu-icon" /> <span className='nav-text'>Manage Station</span></Link>
                            <Link id="t-1" to={'/view-sensors'} className="menu-item" style={{ backgroundColor: isActive('/view-sensors') ? 'olivedrab' : 'transparent' }}> <img src={Sensors} alt="icon" className="menu-icon" /> <span className='nav-text'>Manage Sensor</span></Link>
                            <Link id="t-1" to={'/view-user'} className="menu-item" style={{ backgroundColor: isActive('/view-user') ? 'olivedrab' : 'transparent' }}> <img src={iconComplienceUser} alt="icon" className="menu-icon" /> <span className='nav-text'>Manage User</span></Link>
                            <Link id="t-1" to={'/kyc'} className="menu-item" style={{ backgroundColor: isActive('/kyc') ? 'olivedrab' : 'transparent' }}> <img src={iconSubscription} alt="icon" className="menu-icon" /> <span className='nav-text'>Manage KYC</span></Link>
                            <Link id="t-1" to={'/alerts/template'} className="menu-item" style={{ backgroundColor: isActive('/alerts/template') ? 'olivedrab' : 'transparent' }}> <img src={iconImessage} alt="icon" className="menu-icon" /> <span className='nav-text'>Manage Alert</span></Link>
                            <Link id="t-1" to={'/command-centre'} className="menu-item" style={{ backgroundColor: isActive('/command-centre') ? 'olivedrab' : 'transparent' }}> <img src={CommandCentre} alt="icon" className="menu-icon" /> <span className='nav-text'>Command Centre</span></Link>
                            <Link id="t-1" to={'/service-management'} className="menu-item" style={{ backgroundColor: isActive('/service-management') ? 'olivedrab' : 'transparent' }}><img src={sm} alt='icon' className='menu-icon' /><span className='nav-text'>Service Management</span></Link>
                            <Link id='t-1' to={'/parent-component'} className='menu-item' style={{ backgroundColor: isActive('/parent-component') ? 'olivedrab' : 'transparent' }}><img src={flow} alt='icon' className='menu-icon' /><span className='nav-text'>Flow</span></Link>
                            <Link id='t-1' to={"/activity-add"} className='menu-item' style={{ backgroundColor: isActive('/activity-add') ? 'olivedrab' : 'transparent' }}><img src={log} alt='icon' className='menu-icon' /><span className='nav-text'>Audit Log</span></Link>
                            <Link id='t-1' to={"/checklist"} className="menu-item" style={{ backgroundColor: isActive('/checklist') ? 'olivedrab' : 'transparent' }}><img src={Service_Checklist} alt='icon' className="menu-icon" /><span className='nav-text'>Service Checklist</span></Link>
                        </>
                    }
                    {
                        role === "MANAGER" && <>
                            <Link id="t-1" to={'/service-management'} className="menu-item" style={{ backgroundColor: isActive('/service-management') ? 'olivedrab' : 'transparent' }}><img src={sm} alt='icon' className='menu-icon' /><span className='nav-text'>Service Management</span></Link>
                        </>
                    }
                    {
                        role === "CLIENT" && <>
                        
                        </>
                    }
                </div>
            </div>
        </>
    )
}
export default VerticalMenu;